<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" title="sektor pekerjaan" />
		
		<div class="container">
			<div class="row">
				<div class="left-home col-lg-9 col-md-9 col-sm-9 col-xs-12">
					<div class="pageone">
						<div class="pageone-title" style="margin-bottom: -10PX;">
							<h2><a style='float:left;font-size: 15PX;margin-bottom: -10px;' href="javascript:void(0);" title="">PEKERJAAN TERBARU DI AUSTRALIA</a></h2>
							<p class="line-do"></p>
						</div>
						<div class="pageone-tab">
							<ul class="nav nav-tabs" role="tablist" style="display: flex;flex-wrap: wrap;">
								<li class="active" style="background: #f58220;display: flex;align-items: center;">
									<a href="javascript:void(0);" role="tab" data-toggle="tab">rincian pekerjaan</a>
								</li>
								<li><a href="javascript:void(0);" role="tab" data-toggle="tab">keterangan</a></li>
								<li><a href="javascript:void(0);" role="tab" data-toggle="tab">lowongan untuk pria</a></li>
								<li><a href="javascript:void(0);" role="tab" data-toggle="tab">lowongan untuk wanita</a></li>
							</ul>
							<div class="tab-content" id="style-2">
								<div class="tab-pane active" id="thong-so-ki-thuat">
									<table class="donhang-home">
										<tbody>
											<tr>
												<td>Industri</td>
												<td>Tanggal ujian</td>
												<td>Seks</td>
												<td>Gaji pokok</td>
												<td>Kuantitas</td>
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="[Total biaya 105 juta] Pesanan pengolahan makanan untuk wanita yang akan pergi ke Australia">Pertanian</a></td>
												<td>10/05/2025</td>
												<td>Pria/Wanita</td>
												<td>$6,383 AUD </td>
												<td>30</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Pesanan pertama Tokutei Food dari Vietnam untuk membuat pangsit di provinsi Gunma">Peternakan</a></td>
												<td>15/05/2025</td>
												<td>Pria/Wanita</td>
												<td>$4,800 AUD </td>
												<td>24</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Rekrutmen untuk studi kejuruan di luar negeri di Australia, belajar dan bekerja, kesempatan untuk mendapatkan 4000 AAD">Operator Produksi</a></td>
												<td>28/05/2025</td>
												<td>Pria</td>
												<td>$6,400 AUD </td>
												<td>100</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Pesanan pengolahan makanan di Australia merekrut 18 wanita">mechanic mesin</a></td>
												<td>18/05/2025</td>
												<td>Pria</td>
												<td>$5,833 AUD </td>
												<td>18</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Merekrut 30 Perempuan untuk pekerjaan pengemasan industri di Australia di provinsi Tokyo">Salon Kecantikan</a></td>
												<td>16/05/2025</td>
												<td>Wanita</td>
												<td>$5,922 AUD </td>
												<td>30</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Pesanan tradisional merekrut 100 Perempuan dan 20 laki-laki untuk memproses makanan laut AUSTRALIA">Restoran</a></td>
												<td>25/05/2025</td>
												<td>Pria/Wanita</td>
												<td>$4,800 AUD </td>
												<td>120</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Pesanan stempel logam untuk suku cadang mobil Nissan Australia merekrut 54 orang">Resepsionis Hotel</a></td>
												<td>24/05/2025</td>
												<td>Pria/Wanita </td>
												<td>$4,249 AUD </td>
												<td>54</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Pesanan makanan olahan di Australia merekrut 60 Perempuan untuk bekerja di supermarket">SPA</a></td>
												<td>15/05/2025</td>
												<td>Wanita</td>
												<td>$5,833 AUD </td>
												<td>60</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Pesanan pemrosesan makanan di Kyoto, Australia, merekrut 54 wanita">Perawat</a></td>
												<td>24/05/2025</td>
												<td>Wanita</td>
												<td>$5,333 AUD </td>
												<td>54</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Pabrik pesanan AUSTRALIA merekrut 20 orang di provinsi Osaka">driver</a></td>
												<td>29/05/2025</td>
												<td>Pria </td>
												<td>$5,000 AUD </td>
												<td>20</td>
												
											</tr>
										</tbody>
									</table>
									<!-- <div class="xemthemhome"><a href="javascript:void(0);" title="See more">See more</a></div> -->
								</div>
								
								
							
							</div>
						</div>
					</div>
					<!-- Tin việc làm nước ngoài mới nhất -->
					<div class="widget widget-highlight-job pagetow"  id="topId">
						<h2 class="widget-title" style="display: flex;flex-wrap: wrap;">
							<a @click="changeHash('#topId')" href="javascript:void(0)" title="Lowongan Kerja Luar Negeri Terbaru">Lowongan Kerja Luar Negeri Terbaru 
							<font style="float:right; color:red; text-transform:none; font-size:13px; margin-left:10px;    text-decoration: underline;">Lihat lebih banyak</font></a> 
						</h2>
						<div class="clearfix"></div>
						<div class="widget-wrap scrollbar">
							<div class="widget-content" style="display: none;">
								<ul>
									<li>
										<a href="javascript:void(0);" title="Recruitment for vocational study abroad in Australia, study and work, opportunity to earn 4000 AAD">
											<h3>Recruitment for vocational study abroad in Australia, study and work, opportunity to earn 4000 AAD</h3>
											<h4><span>Salary:</span>4000 $ </h4>
											<h4><span>Nation:</span>Australia</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="41 latest Singapore orders recruiting continuously">
											<h3>41 latest Singapore orders recruiting continuously</h3>
											<h4><span>Salary:</span>40000 </h4>
											<h4><span>Nation:</span>Singapore</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Admission to study in Australia without IELTS English certificate">
											<h3>Admission to study in Australia without IELTS English certificate</h3>
											<h4><span>Salary:</span>25 $/hour </h4>
											<h4><span>Nation:</span>Australia</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Exporting labor to Poland, recruiting male and Perempuan workers. Packing">
											<h3>Exporting labor to Poland, recruiting male and Perempuan workers. Packing</h3>
											<h4><span>Salary:</span>1.100 USD </h4>
											<h4><span>Nation:</span>Ba lan</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Polish labor export recruits male and Perempuan for food work">
											<h3>Polish labor export recruits male and Perempuan for food work</h3>
											<h4><span>Salary:</span>1.100 USD </h4>
											<h4><span>Nation:</span>Ba lan</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Notice of German labor export program with outstanding fees">
											<h3>Notice of German labor export program with outstanding fees</h3>
											<h4><span>Salary:</span>80 million </h4>
											<h4><span>Nation:</span>CHLB Đức</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="E7 Visa Korea is recruiting mechanics and electricians to work at a shipyard">
											<h3>E7 Visa Korea is recruiting mechanics and electricians to work at a shipyard</h3>
											<h4><span>Salary:</span>2,7million won </h4>
											<h4><span>Nation:</span>Korea</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Construction order in Singapore recruits 1,300 unskilled workers">
											<h3>Construction order in Singapore recruits 1,300 unskilled workers</h3>
											<h4><span>Salary:</span>3000 SGD </h4>
											<h4><span>Nation:</span>Singapore</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Notice of recruitment of workers to work in Germany">
											<h3>Notice of recruitment of workers to work in Germany</h3>
											<h4><span>Salary:</span>2400 Eur </h4>
											<h4><span>Nation:</span>Đức</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Philippines labor export recruits Sales Marketing staff with 0 VND fee">
											<h3>Philippines labor export recruits Sales Marketing staff with 0 VND fee</h3>
											<h4><span>Salary:</span>36 million </h4>
											<h4><span>Nation:</span>Philippines</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Labor export to Australia Australia recruits 1500 male and Perempuan workers for agriculture">
											<h3>Labor export to Australia Australia recruits 1500 male and Perempuan workers for agriculture</h3>
											<h4><span>Salary:</span>85 million </h4>
											<h4><span>Nation:</span>Australia Australia</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Hiring chefs to work in Germany, no language required, just insurance required">
											<h3>Hiring chefs to work in Germany, no language required, just insurance required</h3>
											<h4><span>Salary:</span>2.800 Euro </h4>
											<h4><span>Nation:</span>CHLB Đức</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="New Zealand labor export order recruiting male Perempuan restaurant waiter">
											<h3>New Zealand labor export order recruiting male Perempuan restaurant waiter</h3>
											<h4><span>Salary:</span>80 million </h4>
											<h4><span>Nation:</span>New Zealand</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Recruiting construction workers in New Zealand">
											<h3>Recruiting construction workers in New Zealand</h3>
											<h4><span>Salary:</span>4000- 6,500 NZD </h4>
											<h4><span>Nation:</span>New Zealand</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Agriculture industry recruits male and Perempuan farm workers in New Zealand">
											<h3>Agriculture industry recruits male and Perempuan farm workers in New Zealand</h3>
											<h4><span>Salary:</span>5000-5500 NZ </h4>
											<h4><span>Nation:</span>New Zealand</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Recruiting welders to go to Hungary and Slovakia, Europe, low cost, high salary">
											<h3>Recruiting welders to go to Hungary and Slovakia, Europe, low cost, high salary</h3>
											<h4><span>Salary:</span>2.200 Euro </h4>
											<h4><span>Nation:</span>Hungary</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Exporting labor to Hungary with 5 new recruitment orders">
											<h3>Exporting labor to Hungary with 5 new recruitment orders</h3>
											<h4><span>Salary:</span>1.100 USD </h4>
											<h4><span>Nation:</span>Hungary</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="3 orders for exporting Hungarian labor to work as Interpreters, foremen, and electronics">
											<h3>3 orders for exporting Hungarian labor to work as Interpreters, foremen, and electronics</h3>
											<h4><span>Salary:</span>2000USD </h4>
											<h4><span>Nation:</span>Hungary</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Taiwan labor export recruits 15 male and Perempuan workers to produce treadmills in Taichung">
											<h3>Taiwan labor export recruits 15 male and Perempuan workers to produce treadmills in Taichung</h3>
											<h4><span>Salary:</span>23.800 Đài tệ </h4>
											<h4><span>Nation:</span>Đài Loan</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Recruiting restaurant assistants and chefs in Romania">
											<h3>Recruiting restaurant assistants and chefs in Romania</h3>
											<h4><span>Salary:</span>1000 USD </h4>
											<h4><span>Nation:</span>Rumani</h4> </a>
									</li>
								</ul>
							</div>
							
							
							<div class="widget-content"  style="display: flex;flex-wrap: wrap;">
								<ul>
									<li>
										<a href="javascript:void(0);" title="Mendaftarlah untuk studi profesional di luar negeri di Australia, bekerja sambil belajar, dan dapatkan kesempatan mendapatkan penghasilan 3,500">
											<h3>Mendaftarlah untuk studi profesional di luar negeri di Australia, bekerja sambil belajar, dan dapatkan kesempatan mendapatkan penghasilan 3,500</h3>
											<h4><span>Gaji:</span>3500 </h4>
											<h4><span>Negara:</span>Australia</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="">
											<h3>Mengekspor tenaga kerja ke Australia Australia merekrut 1.600 laki-laki dan perempuan untuk bekerja</h3>
											<h4><span>Gaji:</span>68 juta </h4>
											<h4><span>Negara:</span>Australia</h4> </a>
									</li>
									
									<li>
										<a href="javascript:void(0);" title="">
											<h3>40 pesanan Singapura terbaru masih direkrut.</h3>
											<h4><span>Gaji:</span>S$28.000 </h4>
											<h4><span>Negara:</span>Singapura</h4> </a>
									</li>
									
									<li>
										<a href="javascript:void(0);" title="">
											<h3>Perintah ekspor tenaga kerja Selandia Baru merekrut pria dan wanita untuk bertugas di restoran</h3>
											<h4><span>Gaji:</span>80 juta </h4>
											<h4><span>Negara:</span>Selandia Baru</h4> </a>
									</li>
									
									<li>
										<a href="javascript:void(0);" title="">
											<h3>Merekrut pekerja konstruksi di Selandia Baru</h3>
											<h4><span>Gaji:</span>NZD 4000-6,500 </h4>
											<h4><span>Negara:</span>Selandia Baru</h4> </a>
									</li>
									
									<li>
										<a href="javascript:void(0);" title="">
											<h3>Ekspor tenaga kerja Polandia merekrut laki-laki dan perempuan untuk bekerja di bidang pangan</h3>
											<h4><span>Gaji:</span>$1.300 </h4>
											<h4><span>Negara:</span>Polandia</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="">
											<h3>Departemen pertanian Selandia Baru merekrut pria dan wanita untuk bekerja di pertanian</h3>
											<h4><span>Gaji:</span>NZD 5000-5500 </h4>
											<h4><span>Negara:</span>Selandia Baru</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="">
											<h3>Merekrut tukang las untuk pergi ke Italia, Italia, dan Slovakia di Eropa mahal dan dibayar dengan baik</h3>
											<h4><span>Gaji:</span>2.300 euro </h4>
											<h4><span>Negara:</span>Hongaria</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="">
											<h3>Perintah konstruksi Singapura untuk merekrut 1.100 pekerja tidak terampil</h3>
											<h4><span>Gaji:</span>S$3.000 </h4>
											<h4><span>Negara:</span>Singapura</h4> </a>
									</li>
									
									
									<li>
										<a href="javascript:void(0);" title="">
											<h3>Iklan tentang perekrutan pekerja untuk bekerja di Jerman</h3>
											<h4><span>Gaji:</span>2.400 euro </h4>
											<h4><span>Negara:</span>Jerman</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="">
											<h3>Tenaga penjualan dan pemasaran rekrutmen ekspor tenaga kerja Filipina, 0 biaya rupiah Indonesia</h3>
											<h4><span>Gaji:</span>Rp 38 juta </h4>
											<h4><span>Negara:</span>Filipina</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="">
											<h3>Merekrut pelayan dan koki restoran Rumania</h3>
											<h4><span>Gaji:</span>$1.000 </h4>
											<h4><span>Negara:</span>Rumania</h4> </a>
									</li>
									
									
									
									
								</ul>
							</div>
							
						</div>
					</div>
					<div class="clear"></div>
					<div class="xemthemhome" style='margin-top: 80PX;'><a href="javascript:void(0);" title="Lihat selengkapnya"></a></div>
					<!-- ./. Tin việc làm nước ngoài mới nhất -->
					
					
				</div>
				
			</div>
		</div>
		
		<div class="call-mobile" style="bottom: 116PX;display:none;">
			<a class="" href="tel:0348148888"> <img src="/img/gw/phone_mobile.png" alt="phone" title="phone"> 0348.14.8888</a>
		</div>
		
		<div class="call-mobile" style="display:none;">
			<a class="" href="tel:0327089999"> <img src="/img/gw/phone_mobile.png" alt="phone" title="phone"> 0327.08.9999</a>
		</div>
		
		<!--div class="call-mobile" style="bottom: 117px;">
			<a class="" href="tel:0587236666"> <img src="/img/gw/phone_mobile.png" alt="phone" title="phone"> 0587236666</a>
		</div-->
		
	</div>
</template>

<script>
export default {
	data() {
		return {
			addlist: [
				{
					0: '北京',
					1: '上海',
					2: '广州',
					3: '深圳'
				},
				{
					0: '南京',
					1: '长沙',
					2: '重庆',
					3: '西安'
				}
			]
		};
	},
	methods: {
		changeHash(idName) {
		      document.querySelector(idName).scrollIntoView(true);
		    },
		addgo(data) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' });
			} else {
				this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
			}
		},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'address_list'
			}).then(res => {
				this.addlist = res.data;
			});
		}
	},
	created() {
		this.getAddress();
	}
};
</script>

<style src='../../assets/css/ttc.css' scoped></style>
<!-- <style src='../../assets/css/responsive.css' scoped></style> -->
<!-- <style src='../../assets/css/bootstrap.min.css' scoped></style> -->

<style lang="less" scoped>
	// @import "../../assets/css/ttc.css";
	// @import "../../assets/css/responsive.css";
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f2f2f5;
}
.nav-bar {
	background: linear-gradient(90deg,#500cfd,#9e9ce7);
	height: 100px;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 35px;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.van-sidebar {
	width: 180px;
}
.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #f2f2f5;
}
::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
}
::v-deep .van-tabs__line {
	background-color: #500cfd;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.card {
	background-color: #8a637d;
	padding: 0.625rem;
	width: 95%;
	color: white;
	font-size: 0.8125rem;
	margin: 0.625rem auto;
	border-radius: 0.375rem;
}
::v-deep .van-row--flex {
	height: 80px;
	line-height: 80px;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #0bdab0;
	font-size: 1.125rem;
}
.rig-content {
	font-size: 20px;
	// margin-top: 10px;
}
.address {
	width: 94%;
	margin: 0 auto;
}
.van-cell {
    padding: 4vw 2.933vw;
    font-size: 4vw;
    line-height: 4vw;
}
.pageone-title h2 {
margin: 0px;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: bold;
    background: #fff;
    display: inline-block;
    padding-right: 10px;
	}
.pageone-title h2 {
    font-size: 15PX;
    display: block;
	text-transform: uppercase;
	    font-weight: bold;
}
// .container {
//     font-family: Inter,sans-serif;
//     font-weight: 400;
//     color: #fff!important;
// }
.container {
    // font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    // font-size: 14PX;
    // line-height: 1.42857143;
    // color: #333;
    background-color: #fff;
	z-index: 1!important;
}
.pageone-tab .nav-tabs>li {
    width: 50%!important;
}
.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    float: left;
}
.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.nav-tabs {
    border-bottom: 1px solid #ddd;
}
table {
    border-spacing: 0;
    border-collapse: collapse;
}
.tab-content {
    display: block;
    // overflow-x: scroll;
}
.donhang-home tr td{
	word-break: break-all;
}
</style>
